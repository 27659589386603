document.addEventListener("DOMContentLoaded", function() {
  const isPrepressClient = localStorage.medium === "prepress" || location.href?.includes("prepress.ai");

  if (isPrepressClient) {
    const linkAppleTouchIcon = document.createElement("link");
    linkAppleTouchIcon.rel = "apple-touch-icon";
    linkAppleTouchIcon.sizes = "180x180";
    linkAppleTouchIcon.href = "prepress-favicons/apple-touch-icon.png";
    document.head.appendChild(linkAppleTouchIcon);

    const linkIcon32 = document.createElement("link");
    linkIcon32.rel = "icon";
    linkIcon32.type = "image/png";
    linkIcon32.sizes = "32x32";
    linkIcon32.href = "prepress-favicons/favicon-32x32.png";
    document.head.appendChild(linkIcon32);

    const linkIcon16 = document.createElement("link");
    linkIcon16.rel = "icon";
    linkIcon16.type = "image/png";
    linkIcon16.sizes = "16x16";
    linkIcon16.href = "prepress-favicons/favicon-16x16.png";
    document.head.appendChild(linkIcon16);

    const linkMaskIcon = document.createElement("link");
    linkMaskIcon.rel = "mask-icon";
    linkMaskIcon.href = "prepress-favicons/safari-pinned-tab.svg";
    linkMaskIcon.color = "#5bbad5";
    document.head.appendChild(linkMaskIcon);
  }
});
