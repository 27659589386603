import moment from "moment-timezone";
import { store } from "@/store";
import CryptoJS from "crypto-js";
import helperMixin from "@/mixins/helperMixin";
import { cloneDeep } from "lodash";

export default (to, from, next) => {
  const removeUserHash = () => {
    delete localStorage.user
    delete localStorage.hash
    delete localStorage.token
    return false
  }

  const expireControl = (user) => {
    const userExpire = moment(user.exp).tz('America/New_York').format('YYYY-MM-DDTHH:mm:ss')
    const currentDate = moment(new Date()).tz('America/New_York').format('YYYY-MM-DDTHH:mm:ss')

    // If Token is still valid, keep going
    if (userExpire > currentDate) {
      store.commit("setActiveUser", user);
      // Set Shared Modules... for Caching
      store.dispatch("countries/initModules");
      store.dispatch("locations/initModules");
      store.dispatch("templates/initModules");
      store.dispatch("users/initModules")
      return user
    } else {
      return removeUserHash()
    }
  }

  const userControl = () => {
    //! If Page Refreshed
    if(!store.getters['isAuthUser']) {
      if (localStorage.hash) {
        const user = JSON.parse(CryptoJS.AES.decrypt(localStorage.hash, store.getters.cryptPassword).toString(CryptoJS.enc.Utf8))

        return expireControl(user)
      } else {
        return removeUserHash()
      }
    } else {
      const user = store.getters['activeUser']

      if(!user) return removeUserHash()
      return expireControl(user)
    }
  }

  const isPrepressClient = localStorage.medium === "prepress" || location.href?.includes("prepress.ai");
  const defaultRoutes = ["core", "default"]
  const user = userControl()
  if(to.matched.some(r => r.meta.authRequired)) {
    if(!user) {
      localStorage.toPath = to.path
      next({ name: 'LoginPage' })
    } else {
      if(defaultRoutes.includes(to?.name) && isPrepressClient) next({ name: 'PrepressFileUploader' })
      else {
        next()
      }
    }
  } else if(to.matched.some(r => r.meta.guestRequired)) {
    if(defaultRoutes.includes(to?.name) && user && isPrepressClient) next({ name: 'PrepressFileUploader' })
    else if(user) next({ name: 'default' })
    else next(true)
  } else next(true)
};
