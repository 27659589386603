const routes = [
  {
    name: "ClientSettingsUpdatePage",
    path: "/client_settings/:id/:settings_key/:sub_settings_key",
    component: () => import("@/views/app/pages/client_settings/distributor")
  },
  {
    name: "ClientSettingsListPage",
    path: "/client_settings",
    component: () => import("@/views/app/pages/client_settings")
  }
];

export default routes;
